import {Typography} from "@mui/material";
import React from "react";

export function Json2Html(json) {
    return <>
        {
            json.map((v, k) => {
                if (v.type === "list") {
                    return <ul style={{padding: 0}} key={k}
                               className={"content"}>
                        {v.content.map((item, key) => {
                            return <li key={key}
                                       className={"content"}
                                       style={{
                                           paddingLeft: "1rem",
                                           marginLeft: 0,
                                           marginTop: 4,
                                           marginBottom: 4
                                       }}>
                                {
                                    typeof item === 'object' ? Json2Html([item].flat()) : item
                                }
                            </li>;

                        })}
                    </ul>
                } else if (v.type === "link") {
                    const content = v.content.replace("%%", `<a href="${v.link}">${v.text}</a>`);
                    return <Typography key={k} dangerouslySetInnerHTML={{__html: content}} style={{
                        marginTop: 4,
                        marginBottom: 4
                    }}/>;

                } else if (Array.isArray(v)) {
                    return Json2Html(v)
                } else {
                    return <Typography key={k} style={{
                        marginTop: 4,
                        marginBottom: 4
                    }}>{v}</Typography>;
                }
            })
        }
    </>
}