import React from "react";
import {Box, Card, CardContent, Collapse, Container, Divider, Grid, ThemeProvider, Typography} from "@mui/material";
import {withRouter} from "../Components/withRouter";
import axios from "axios";
import {Json2Html} from "../Components/Json2Html";

class Help extends React.Component {
    constructor(props) {
        super(props);
        this.props = props
        this.state = {
            content: [],
            title: "",
        }
        this.fetchHelp.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.content.length === 0) {
            this.fetchHelp();
        }
    }

    componentDidMount() {
        this.fetchHelp();
    }

    fetchHelp() {
        if (window.helpPreLoader) {
            this.setState({content: window.helpPreLoader.content, title: window.helpPreLoader.title})
        } else {
            let formData = new FormData();
            formData.append("article", this.props.match.params.id)
            formData.append("product", this.props.match.params.product)
            axios.post("/api/getHelp", formData, {
                withCredentials: true
            }).then(r => {
                this.setState({content: r.data.data.content, title: r.data.data.title})
            })
        }
    }

    render() {

        const {match} = this.props;
        return (
            <>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={8}>
                            <Card sx={{margin: 3}}>
                                <CardContent>
                                    <Box m={2}>
                                        <Typography variant={"h4"}>{this.state.title}</Typography>
                                        {Json2Html(this.state.content)}
                                    </Box>
                                </CardContent>

                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </>

        )
    }
}

export default withRouter(Help);
