import React from "react";
import {Container, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props
        this.state = {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentDidMount() {
    }


    render() {

        const {match} = this.props;
        return (
            <div style={{height: 100}}>
                <Container>
                    <Grid container>
                        <Grid item xs={4}>
                            &copy; {(new Date()).getFullYear()} Zentring
                        </Grid>
                        <Grid item xs={4}>

                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                sx={{width: "100%", maxWidth: 300}}
                            >
                                <InputLabel id="demo-simple-select-label">中文(台灣)</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={"zh-TW"}
                                    label="Age"
                                    onChange={() => {
                                    }}
                                >
                                    <MenuItem value={"zh-TW"}>中文(台灣)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>
            </div>

        )
    }
}

export default Footer;
