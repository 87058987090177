import React from "react";
import {Link} from "react-router-dom";
import {Button, Container, Grid, IconButton, Paper, Typography} from "@mui/material";
import {withStyles} from '@mui/styles';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {Line} from "../Components/Line";

const classes = theme => ({
    headBlock: {
        backgroundImage: "url(//dummyimage.com/1920x500/ccc/888.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: 500,
    },
    link: {
        // color: "#9199BE",
        textDecoration: "none",
        verticalAlign: "middle",
    },
});


class Home extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <div>
                <Container>
                    <Link to={"/cloud/1"}>VM</Link><br/>
                </Container>
            </div>
        );
    }
}

export default (withStyles(classes)(Home));
