import './App.css';
import Top from "./Components/Top";
import React from "react";
import {Box, ThemeProvider} from "@mui/material";
import {StylesProvider} from "@mui/styles";
import {createTheme,} from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import Help from "./Page/Help";
import axios from "axios";
import {Route, Routes} from "react-router-dom";
import Home from "./Page/Home";
import Footer from "./Components/Footer";

const defaultTheme = createTheme();


class App extends React.Component {
    constructor(props) {
        super(props);
        this.props = props
        this.state = {}
        this.handleWindowSizeChange.bind(this);
    }

    handleWindowSizeChange = () => {
        this.setState({windowWidth: window.innerWidth});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);

    }


    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loop: setInterval(() => {
                    //Fixed container not trigger resize event by loop
                    this.handleWindowSizeChange();
                }, 1000)
            })
        }, 2000)

        window.addEventListener('resize', this.handleWindowSizeChange);
        // this.setState({groupId: this.params.group, isGroupLoaded: true})
    }

    render() {
        return (
            <ThemeProvider theme={defaultTheme}>
                <StylesProvider injectFirst>
                    <Top/>
                    <div style={{minHeight: "calc(100VH - 50px - 100px)"}}>
                        <Routes>
                            <Route path={"/:product/:id"} element={<Help/>}/>
                            <Route path={"/*"} element={<Home/>}/>
                        </Routes>
                    </div>
                    <Footer>

                    </Footer>
                </StylesProvider>
            </ThemeProvider>)
    }
}

export default App;